import css from './Teleprompt.module.sass'
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import AnimatedEllipsis from '../AnimatedEllipsis'
import Button from '../Button'
import { TextArea } from '../Form'
import Text from '../Text'

import { EDIT_TELEPROMPT_CLASSNAME } from '../../constants/classnames'
import { useSaveCompanyTelepromptMutation } from '../../helpers/hooks'

const Teleteleprompt = ({
  topicId,
  subject,
  defaultTeleprompt,
  example,
  editable,
}) => {
  const [teleprompt, setTeleprompt] = useState(defaultTeleprompt || '')
  const [editing, setEditing] = useState()

  const inputRef = useRef()
  if (!editing && defaultTeleprompt !== teleprompt) {
    setTeleprompt(defaultTeleprompt)
  }

  const [savePrompt, { loading: saving }] = useSaveCompanyTelepromptMutation({
    onCompleted: (data) => {
      if (!data.errors) {
        setEditing(false)
      }
    },
  })

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
      inputRef.current.selectionStart = 100000
    }
  }, [editing])

  const words = useMemo(() => {
    const parts = teleprompt.trim().split(/\s+/)
    return parts[0] === '' ? 0 : parts.length
  }, [teleprompt])

  const handleUpdate = useCallback((obj) => {
    setTeleprompt(obj.teleprompt)
  }, [])

  const handleEditClick = useCallback(() => {
    setEditing(true)
  }, [])

  const handleSaveClick = useCallback(() => {
    if (teleprompt.trim() === defaultTeleprompt) {
      setEditing(false)
    } else {
      if (!saving) {
        savePrompt({
          variables: {
            input: {
              id: topicId,
              subject: subject,
              teleprompt: teleprompt,
            },
          },
        })
      }
    }
  }, [defaultTeleprompt, teleprompt, topicId, subject, saving, savePrompt])

  let textTeleprompt = teleprompt ? 'Edit Script' : 'Enter Script'
  return (
    <div className={css.containerTextArea}>
      {editing && editable ? (
        <>
          <TextArea
            variant="teleprompt"
            id="telepromptEditScript"
            name="teleprompt"
            value={teleprompt}
            rows="1"
            autoHeight
            scrollable={true}
            innerRef={(el) => (inputRef.current = el)}
            onUpdate={handleUpdate}
          />
          <Text variant={null}>
            {words} word{words === 1 ? '' : 's'}
          </Text>{' '}
          <Button
            variant="primaryNarrow"
            offset="half-left"
            disabled={saving}
            onClick={handleSaveClick}
          >
            {saving ? (
              <>
                Saving
                <AnimatedEllipsis />
              </>
            ) : (
              'Save script'
            )}
          </Button>
        </>
      ) : (
        (editable || defaultTeleprompt) && (
          <>
            <span className={css.teleprompt}>
              {!teleprompt ? `${example}` : teleprompt}
            </span>

            {editable && (
              <Button
                id="teleprompt-button"
                className={EDIT_TELEPROMPT_CLASSNAME}
                variant="link"
                aria-label={teleprompt ? 'Edit Script' : 'Enter Script'}
                onClick={handleEditClick}
              >
                {textTeleprompt}
              </Button>
            )}
          </>
        )
      )}
    </div>
  )
}

Teleteleprompt.propTypes = {
  topicId: PropTypes.number,
  subject: PropTypes.string,
  defaultTeleprompt: PropTypes.string,
  example: PropTypes.string,
  editable: PropTypes.bool,
}

export default Teleteleprompt
